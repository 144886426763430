<template>
  <div class="tab-pane" id="empresa">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="info-text">
          Vamos começar pelas informações da empresa.
        </h4>
      </div>
      <div class="col-sm-12">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">CNPJ</label>
            <input
              id="textCNPJ"
              name="textCNPJ"
              type="tel"
              class="form-control"
              required
              tabindex="1"
              v-model="textCNPJ"
              @blur="consultarDadosReceitaWS()"
              v-mask="'##.###.###/####-##'"
            />
          </div>
        </div>
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div
            :class="[
              campoPreenchido
                ? 'form-group label-floating'
                : 'form-group label-floating is-empty',
            ]"
          >
            <label class="control-label">Razão Social</label>
            <input
              id="textRazaoSocial"
              name="textRazaoSocial"
              type="text"
              class="form-control"
              maxlength="100"
              tabindex="2"
              v-model="textRazaoSocial"
            />
          </div>
        </div>
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div
            :class="[
              campoPreenchido
                ? 'form-group label-floating'
                : 'form-group label-floating is-empty',
            ]"
          >
            <label class="control-label">Atividade principal</label>
            <input
              id="textAtividadePrincipal"
              name="textAtividadePrincipal"
              type="text"
              class="form-control"
              maxlength="100"
              tabindex="3"
              v-model="textAtividadePrincipal"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div
            :class="[
              campoPreenchido
                ? 'form-group label-floating'
                : 'form-group label-floating is-empty',
            ]"
          >
            <label class="control-label">Logradouro</label>
            <input
              id="textLogradouro"
              name="textLogradouro"
              type="text"
              class="form-control"
              maxlength="100"
              tabindex="4"
              v-model="textLogradouro"
            />
          </div>
        </div>
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">Telefone</label>
            <input
              id="textTelefone"
              name="textTelefone"
              type="tel"
              class="form-control"
              tabindex="6"
              ref="textTelefoneRef"
              v-model="textTelefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div
            :class="[
              campoPreenchido
                ? 'form-group label-floating'
                : 'form-group label-floating is-empty',
            ]"
          >
            <label class="control-label">Número</label>
            <input
              id="textNumero"
              name="textNumero"
              type="text"
              class="form-control"
              maxlength="10"
              tabindex="5"
              v-model="textNumero"
            />
          </div>
        </div>
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">Inscrição Estadual</label>
            <input
              id="textInscricaoEstadual"
              name="textInscricaoEstadual"
              type="text"
              class="form-control"
              maxlength="15"
              tabindex="7"
              v-model="textInscricaoEstadual"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">Operadora</label>
            <select
              id="selectOperadora"
              name="selectOperadora"
              class="form-control"
              tabindex="8"
              v-model="selectOperadora"
            >
              <option disabled="" selected=""></option>
              <option
                v-for="(item, index) in listaOperadoras"
                :key="index"
                :value="item"
                >{{ item }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <input
            type="button"
            class="btn btn-next btn-fill btn-danger btn-wd"
            value="TESTE"
            @click="teste"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import _replace from 'lodash/replace'; // https://lodash.com/docs/4.17.15#replace
import { mask } from 'vue-the-mask'; // https://vuejs-tips.github.io/vue-the-mask/
// https://github.com/euvl/vue-notification/

export default {
  directives: {
    mask,
  },
  data: function() {
    return {
      listaOperadoras: ['Linha nova', 'Claro', 'Vivo', 'Tim', 'Oi', 'Nextel'],
      campoPreenchido: false,
      textCNPJ: null,
      textRazaoSocial: null,
      textAtividadePrincipal: null,
      textLogradouro: null,
      textTelefone: null,
      textNumero: null,
      textInscricaoEstadual: null,
      selectOperadora: null,
      dadosApiReceitaWS: {},
    };
  },
  methods: {
    consultarDadosReceitaWS() {
      if (this.textCNPJ !== '') {
        var cnpj = _replace(this.textCNPJ, /[./-]+/g, ''),
          url = 'server/buscar_dados_api_receita_ws.php',
          params = new URLSearchParams();

        var cnpjValido = this.validarCnpj(cnpj);

        if (cnpjValido) {
          params.append('cnpj', cnpj);
          this.campoPreenchido = false;

          axios
            .post(url, params)
            .then((resposta) => this.respostaDadosReceitaWS(resposta.data))
            .catch((erro) => console.error(erro));
          return;
        }

        this.$notify({
          group: 'notificacao',
          title: 'Atenção',
          text: 'Este CNPJ é inválido!',
          type: 'warn',
        });
      }
    },
    respostaDadosReceitaWS(data) {
      this.campoPreenchido = true;
      if (data.status === 'OK') {
        this.textRazaoSocial = data.nome;
        this.textAtividadePrincipal = data.atividade_principal[0].text;
        this.textLogradouro = data.logradouro;
        this.textNumero = data.numero;
        this.$refs.textTelefoneRef.focus();
        this.dadosApiReceitaWS = data;
        return;
      }

      this.$notify({
        group: 'notificacao',
        title: 'Atenção',
        text: 'Nenhum dado foi encontrado para o CNPJ consultado',
        type: 'warn',
      });
    },
    dadosEmpresa() {
      // Método chamado pelo App.vue
      return {
        empresa: {
          cnpj: this.removerCaracteresEspeciais(this.textCNPJ),
          razao_social: this.removerCaracteresEspeciais(this.textRazaoSocial),
          atividade_principal: this.removerCaracteresEspeciais(
            this.textAtividadePrincipal
          ),
          logradouro: this.removerCaracteresEspeciais(this.textLogradouro),
          telefone: this.removerCaracteresEspeciais(this.textTelefone),
          numero: this.removerCaracteresEspeciais(this.textNumero),
          inscricao_estadual: this.removerCaracteresEspeciais(
            this.textInscricaoEstadual
          ),
          operadora: this.removerCaracteresEspeciais(this.selectOperadora),
        },
      };
    },
    dadosReceitaWS() {
      // Método chamado pelo App.vue
      return this.dadosApiReceitaWS;
    },
    validarCnpj(cnpj) {
      if (cnpj === '') {
        return false;
      }

      if (cnpj.length !== 14) {
        return false;
      }

      // Elimina CNPJs inválidos conhecidos
      if (
        cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999'
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }

      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (resultado != digitos.charAt(0)) {
        return false;
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (resultado != digitos.charAt(1)) {
        return false;
      }

      return true;
    },
    selecionarAba() {
      this.$emit('aba-selecionada', 1);
    },
    removerCaracteresEspeciais(texto) {
      return _replace(texto, /(\r\n|\n|\r|"|'|`)/g, '').trim();
    },
    teste() {
      let cnpj = this.removerCaracteresEspeciais(this.textCNPJ),
        razao_social = this.removerCaracteresEspeciais(this.textRazaoSocial),
        atividade_principal = this.removerCaracteresEspeciais(
          this.textAtividadePrincipal
        ),
        logradouro = this.removerCaracteresEspeciais(this.textLogradouro),
        telefone = this.removerCaracteresEspeciais(this.textTelefone),
        numero = this.removerCaracteresEspeciais(this.textNumero),
        inscricao_estadual = this.removerCaracteresEspeciais(
          this.textInscricaoEstadual
        ),
        operadora = this.removerCaracteresEspeciais(this.selectOperadora);
      console.log(cnpj);
      console.log(razao_social);
      console.log(atividade_principal);
      console.log(logradouro);
      console.log(telefone);
      console.log(numero);
      console.log(inscricao_estadual);
      console.log(operadora);
    },
  },
};
</script>

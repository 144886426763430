<template>
  <div id="appDetalhes">
    <main role="main" class="container" v-if="dadosCarregados">
      <!-- <h1>{{ guid }}</h1> -->
      <h1>Informações detalhadas do pedido</h1>
      <p class="lead">
        Todas as informações relacionadas ao pedido e ao CNPJ
        {{ this.dadosPedido.cnpj }}
      </p>
      <h3>
        Razão Social:
        {{ this.dadosPedido.razaoSocial }}
      </h3>
      <h4>
        Data de realização do cadastro (GMT -0300 | Horário Padrão de Brasília):
        {{ this.dadosPedido.dataCadastro }}
      </h4>
      <div style="height:30px;"></div>
      <div class="row">
        <div class="col-sm-12 ">
          <div>
            <div class="alert alert-primary" role="alert">
              <h2>Dados da Empresa</h2>
            </div>
            <ul class="list-group">
              <li class="list-group-item">
                <strong>CNPJ: </strong>{{ this.empresa.cnpj }}
              </li>
              <li class="list-group-item">
                <strong>Razão social: </strong>{{ this.empresa.razao_social }}
              </li>
              <li class="list-group-item">
                <strong>Atividade principal: </strong
                >{{ this.empresa.atividade_principal }}
              </li>
              <li class="list-group-item">
                <strong>Logradouro: </strong>{{ this.empresa.logradouro }}
              </li>
              <li class="list-group-item">
                <strong>Número: </strong>{{ this.empresa.numero }}
              </li>
              <li class="list-group-item">
                <strong>Telefone: </strong>{{ this.empresa.telefone }}
              </li>
              <li class="list-group-item">
                <strong>Inscrição estadual: </strong
                >{{ this.empresa.inscricao_estadual }}
              </li>
              <li class="list-group-item">
                <strong>Operadora: </strong>{{ this.empresa.operadora }}
              </li>
            </ul>
            <div style="height:30px;"></div>
          </div>

          <div v-if="this.representantes">
            <div class="alert alert-primary" role="alert">
              <h2>Contatos</h2>
            </div>
            <ul
              class="list-group"
              v-for="(item, index) in this.representantes"
              :key="index"
            >
              <li class="list-group-item">
                <strong>Nome: </strong>{{ item.nome }}
              </li>
              <li class="list-group-item">
                <strong>E-mail: </strong>{{ item.email }}
              </li>
              <li class="list-group-item">
                <strong>CPF: </strong>{{ item.cpf }}
              </li>
              <li class="list-group-item">
                <strong>Telefone: </strong>{{ item.telefone }}
              </li>
              <li class="list-group-item">
                <strong>RG: </strong>{{ item.rg }}
              </li>
              <li class="list-group-item">
                <strong>Tipo: </strong>{{ item.tipo }}
              </li>
            </ul>
            <div style="height:30px;"></div>
          </div>

          <div>
            <div class="alert alert-primary" role="alert">
              <h2>Pedidos</h2>
            </div>
            <ul
              class="list-group"
              v-for="(item, index) in this.pedidos"
              :key="index"
            >
              <li class="list-group-item">
                <strong>Telefone: </strong>{{ item.ddd }} {{ item.telefone }}
              </li>
              <li class="list-group-item">
                <strong>Tipo: </strong>{{ item.tipo }}
              </li>
              <li class="list-group-item">
                <strong>Operadora: </strong>{{ item.operadora }}
              </li>
              <li class="list-group-item">
                <strong>Pacote: </strong>{{ item.pacote_plano }}
              </li>
              <li class="list-group-item">
                <strong>Valor: </strong>R$ {{ item.pacote_valor }}
              </li>
              <li class="list-group-item">
                <strong>Desconto: </strong>R$ {{ item.pacote_desconto }}
              </li>
              <li class="list-group-item">
                <strong>Total: </strong>R$ {{ item.pacote_valor_total }}
              </li>
              <li class="list-group-item" v-if="item.convergencia">
                <strong>Convergência (número contrato): </strong
                >{{ verificarCampoNuloOuIndefinido(item.convergencia) }}
              </li>
              <li class="list-group-item" v-if="item.observacao">
                <strong>Observação: </strong
                >{{ verificarCampoNuloOuIndefinido(item.observacao) }}
              </li>
              <div style="height:15px;"></div>
            </ul>
            <div style="height:15px;"></div>
          </div>

          <div>
            <div class="alert alert-primary" role="alert">
              <h2>Aparelhos</h2>
            </div>
            <ul
              class="list-group"
              v-for="(item, index) in this.aparelhos"
              :key="index"
            >
              <li class="list-group-item">
                <strong>Fabricante: </strong>{{ item.fabricante }}
              </li>
              <li class="list-group-item">
                <strong>Modelo: </strong>{{ item.gerencial }}
              </li>
              <li class="list-group-item">
                <strong>Código: </strong>{{ item.codigo }}
              </li>
              <li class="list-group-item">
                <strong>SIM Card: </strong>{{ item.sim_card }}
              </li>
              <li class="list-group-item">
                <strong>Tecnologia: </strong>{{ item.tecnologia }}
              </li>
              <li class="list-group-item" v-if="item.faixa">
                <strong>Faixa: </strong
                >{{ verificarCampoNuloOuIndefinido(item.faixa) }}
              </li>
              <li class="list-group-item" v-if="item.pontuacao">
                <strong>Pontuação: </strong
                >{{ verificarCampoNuloOuIndefinido(item.pontuacao) }}
              </li>
              <li class="list-group-item">
                <strong>Valor: </strong>R$ {{ item.valor }}
              </li>
              <li class="list-group-item">
                <strong>Desconto: </strong>R$ {{ item.desconto }}
              </li>
              <li class="list-group-item">
                <strong>Total: </strong>R$ {{ item.total }}
              </li>
              <div style="height:15px;"></div>
            </ul>
            <div style="height:15px;"></div>
          </div>

          <div>
            <div class="alert alert-info" role="alert">
              <h2>Dados Receita Federal</h2>
            </div>
            <ul class="list-group">
              <li class="list-group-item">
                <strong>Data da Situação: </strong
                >{{ this.receitaWS.data_situacao }}
              </li>
              <li class="list-group-item">
                <strong>Complemento: </strong>{{ this.receitaWS.complemento }}
              </li>
              <li class="list-group-item">
                <strong>Razão Social: </strong>{{ this.receitaWS.nome }}
              </li>
              <li class="list-group-item">
                <strong>UF: </strong>{{ this.receitaWS.uf }}
              </li>
              <li class="list-group-item">
                <strong>Telefone: </strong>{{ this.receitaWS.telefone }}
              </li>
              <li class="list-group-item">
                <strong>E-mail: </strong>{{ this.receitaWS.email }}
              </li>
              <li class="list-group-item">
                <strong>Situação: </strong>{{ this.receitaWS.situacao }}
              </li>
              <li class="list-group-item">
                <strong>Bairro: </strong>{{ this.receitaWS.bairro }}
              </li>
              <li class="list-group-item">
                <strong>Logradouro: </strong>{{ this.receitaWS.logradouro }}
              </li>
              <li class="list-group-item">
                <strong>Número: </strong>{{ this.receitaWS.numero }}
              </li>
              <li class="list-group-item">
                <strong>CEP: </strong>{{ this.receitaWS.cep }}
              </li>
              <li class="list-group-item">
                <strong>Município: </strong>{{ this.receitaWS.municipio }}
              </li>
              <li class="list-group-item">
                <strong>Data da abertura: </strong>{{ this.receitaWS.abertura }}
              </li>
              <li class="list-group-item">
                <strong>Natureza Jurídica: </strong
                >{{ this.receitaWS.natureza_juridica }}
              </li>
              <li class="list-group-item">
                <strong>CNPJ: </strong>{{ this.receitaWS.cnpj }}
              </li>
              <li class="list-group-item">
                <strong>Tipo: </strong>{{ this.receitaWS.tipo }}
              </li>
              <li class="list-group-item">
                <strong>Nome Fantasia: </strong>{{ this.receitaWS.fantasia }}
              </li>
              <li class="list-group-item">
                <strong
                  >Ente Federativo Responsável (apenas adm. pública): </strong
                >{{ this.receitaWS.efr }}
              </li>
              <li class="list-group-item">
                <strong>Motivo da Situação: </strong
                >{{ this.receitaWS.motivo_situacao }}
              </li>
              <li class="list-group-item">
                <strong>Situação Especial: </strong
                >{{ this.receitaWS.situacao_especial }}
              </li>
              <li class="list-group-item">
                <strong>Data da situação especial: </strong
                >{{ this.receitaWS.data_situacao_especial }}
              </li>
              <li class="list-group-item">
                <strong>Capital social: </strong
                >{{ this.receitaWS.capital_social }}
              </li>
            </ul>
            <div style="height:30px;"></div>
          </div>

          <div>
            <div class="alert alert-info" role="alert">
              <h2>Atividade Principal</h2>
            </div>
            <ul class="list-group">
              <li class="list-group-item">
                <strong>CNAE: </strong
                >{{ this.receitaWS.atividade_principal[0].code }}
              </li>
              <li class="list-group-item">
                <strong>Descrição: </strong
                >{{ this.receitaWS.atividade_principal[0].text }}
              </li>
              <div style="height:15px;"></div>
            </ul>
            <div style="height:15px;"></div>
          </div>

          <div>
            <div class="alert alert-info" role="alert">
              <h2>Atividades Secundárias</h2>
            </div>
            <ul
              class="list-group"
              v-for="(item, index) in this.receitaWS.atividades_secundarias"
              :key="index"
            >
              <li class="list-group-item">
                <strong>CNAE: </strong>{{ item.code }}
              </li>
              <li class="list-group-item">
                <strong>Descrição: </strong>{{ item.text }}
              </li>
              <div style="height:15px;"></div>
            </ul>
            <div style="height:15px;"></div>
          </div>

          <div>
            <div class="alert alert-info" role="alert">
              <h2>Quadro de Sócios e Administradores</h2>
            </div>
            <ul
              class="list-group"
              v-for="(item, index) in this.receitaWS.qsa"
              :key="index"
            >
              <li class="list-group-item">
                <strong>Nome do sócio: </strong>{{ item.nome }}
              </li>
              <li class="list-group-item">
                <strong>Qualificação do Sócio: </strong>{{ item.qual }}
              </li>
              <li class="list-group-item">
                <strong>País de origem (somente para estrangeiros): </strong
                >{{ item.pais_origem }}
              </li>
              <li class="list-group-item">
                <strong
                  >Nome do representante legal (somente para estrangeiros): </strong
                >{{ item.nome_rep_legal }}
              </li>
              <li class="list-group-item">
                <strong
                  >Qualificação do representante legal (somente para
                  estrangeiros): </strong
                >{{ item.qual_rep_legal }}
              </li>
              <div style="height:15px;"></div>
            </ul>
            <div style="height:15px;"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'AppDetalhes',
  mounted() {
    this.init();
  },
  data: function() {
    return {
      guid: null,
      dadosPedido: {
        cnpj: null,
        dataCadastro: null,
        idCadastro: null,
        razaoSocial: null,
      },
      dadosCarregados: false,
      empresa: null,
      representantes: null,
      pedidos: null,
      aparelhos: null,
      receitaWS: null,
    };
  },
  methods: {
    init() {
      console.log('init()');
      this.obterParametrosURL();
      this.carregarDetalhesPedido();
    },
    obterParametrosURL() {
      var urlParams = new URLSearchParams(window.location.search);
      this.guid = urlParams.get('guid');
      console.log(this.guid);
    },
    carregarDetalhesPedido() {
      var url = 'server/cadastro/carregar.php',
        params = new URLSearchParams();
      console.log(this.guid);
      params.append('guid', this.guid);
      axios
        .post(url, params)
        .then((resposta) => this.respostaDetalhesPedido(resposta))
        .catch((erro) => console.error(erro));
      return;
    },
    respostaDetalhesPedido(resposta) {
      let data = resposta.data;
      console.log(resposta);
      console.log(data);
      this.dadosPedido.cnpj = data[0].CNPJ;
      this.dadosPedido.dataCadastro = data[0].DATA_CADASTRO;
      this.dadosPedido.idCadastro = data[0].ID_CADASTRO;
      this.dadosPedido.razaoSocial = data[0].RAZAO_SOCIAL;
      var cadastroRaiz = JSON.parse(data[0].JSON_CADASTRO);
      var receitaRaiz = JSON.parse(data[0].JSON_RECEITA_WS);

      this.empresa = cadastroRaiz.empresa.empresa;
      this.representantes = cadastroRaiz.contatos.representantes;
      this.pedidos = cadastroRaiz.pedido.pedidos;
      this.aparelhos = cadastroRaiz.aparelhos.aparelhos;
      this.receitaWS = receitaRaiz;
      this.dadosCarregados = true;
    },
    verificarCampoNuloOuIndefinido(campo) {
      if (campo === undefined || campo === null) {
        return '';
      }

      return campo;
    },
  },
};
</script>

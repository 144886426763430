<template>
  <div>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Tipo de aparelho</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
          <router-link
            class="btn btn-sm btn-outline-secondary"
            to="/tipo-aparelho/inserir"
            >NOVO REGISTRO</router-link
          >
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-sm" v-if="this.dadosCarregados">
        <thead>
          <tr>
            <th>#</th>
            <th>Fabricante</th>
            <th>Descrição</th>
            <th>SIM Card</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in this.dadosTabelaListagem" :key="index">
            <td>{{ item.ID_APARELHO }}</td>
            <td>{{ item.FABRICANTE }}</td>
            <td>{{ item.GERENCIAL }}</td>
            <td>{{ item.SIMCARD }}</td>
            <td style="width: 15%;">
              <router-link
                :to="{
                  name: 'tipo-aparelho-editar',
                  params: { id: item.ID_APARELHO },
                }"
                >EDITAR</router-link
              >
              &nbsp;&nbsp;
              <router-link
                :to="{
                  name: 'tipo-aparelho-remover',
                  params: { id: item.ID_APARELHO },
                }"
                >REMOVER</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'tipo-aparelho-listar',
  mounted() {
    this.init();
  },
  data: function() {
    return {
      dadosTabelaListagem: null,
      dadosCarregados: false,
    };
  },
  methods: {
    init() {
      this.carregarListagem();
    },
    carregarListagem() {
      var url = 'server/tipo_aparelho/listar.php';
      axios
        .post(url)
        .then((resposta) => this.respostaListagem(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    respostaListagem(data) {
      this.dadosTabelaListagem = data;
      this.dadosCarregados = true;
    },
  },
};
</script>

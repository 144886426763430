<template>
  <div id="appListagem">
    <main role="main" class="container" v-if="this.dadosCarregados">
      <h1>Listagem dos pedidos realizados</h1>
      <p class="lead">
        Acesse o link do pedido para visualizar seus detalhes
      </p>

      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>CNPJ</th>
              <th>Razão Social</th>
              <th>Cadastro (GMT -0300)</th>
              <th>Detalhes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in this.dadosTabelaListagem" :key="index">
              <td>{{ item.ID_CADASTRO }}</td>
              <td>{{ item.CNPJ }}</td>
              <td>{{ item.RAZAO_SOCIAL }}</td>
              <td>{{ item.DATA_CADASTRO }}</td>
              <td><a target="_blank" :href="item.LINK">Detalhes</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'appListagem',
  mounted() {
    this.init();
  },
  data: function() {
    return {
      dadosTabelaListagem: null,
      dadosCarregados: false,
    };
  },
  methods: {
    init() {
      this.carregarListagem();
    },
    carregarListagem() {
      var url = 'server/cadastro/listar.php';
      axios
        .post(url)
        .then((resposta) => this.respostaListagem(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    respostaListagem(data) {
      this.dadosTabelaListagem = data;
      this.dadosCarregados = true;
    },
  },
};
</script>

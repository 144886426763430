<template>
  <div class="tab-pane" id="contato">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="info-text">Contatos</h4>
      </div>
      <div class="col-sm-12">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">Nome</label>
            <input
              id="textContatoNome"
              name="textContatoNome"
              type="text"
              class="form-control"
              maxlength="100"
              tabindex="9"
              :required="campoObrigatorio"
              v-model="textContatoNome"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">E-mail</label>
            <input
              id="textContatoEmail"
              name="textContatoEmail"
              type="text"
              class="form-control"
              maxlength="100"
              tabindex="10"
              v-model="textContatoEmail"
            />
          </div>
        </div>
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">CPF</label>
            <input
              id="textContatoCPF"
              name="textContatoCPF"
              type="text"
              class="form-control"
              tabindex="12"
              v-model="textContatoCPF"
              v-mask="'###.###.###-##'"
              @blur="verificarCpfValido()"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">Telefone</label>
            <input
              id="textContatoTelefone"
              name="textContatoTelefone"
              type="text"
              class="form-control"
              tabindex="11"
              :required="campoObrigatorio"
              v-model="textContatoTelefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
            />
          </div>
        </div>
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">RG</label>
            <input
              id="textContatoRG"
              name="textContatoRG"
              type="text"
              class="form-control"
              maxlength="10"
              tabindex="13"
              v-model="textContatoRG"
            />
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label fonte-15"
              >Tipo do contato (pode ter mais de uma função)</label
            >
            <br />
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="Representante legal"
                id="tipo1"
                v-model="textContatoTipo"
              />
              <label class="form-check-label margem-esquerda" for="tipo1">
                Representante legal
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="Administrador da conta"
                id="tipo2"
                v-model="textContatoTipo"
              />
              <label class="form-check-label margem-esquerda" for="tipo2">
                Administrador da conta
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value="Responsável por receber os aparelhos"
                id="tipo3"
                v-model="textContatoTipo"
              />
              <label class="form-check-label margem-esquerda" for="tipo3">
                Responsável por receber os aparelhos X
              </label>
            </div>
          </div>
        </div>
      </div>
      ]
      <div class="col-sm-12">
        <div class="pull-right">
          <div class="input-group">
            <input
              type="button"
              class="btn btn-danger btn-wd"
              id="buttonAdicionar"
              value="Adicionar contato"
              @click="adicionarItemTabela"
            />
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <table class="table" id="tableContatos">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Tipo</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody id="dataTable">
            <tr v-for="(item, index) in listaTabelaContatos" :key="index">
              <td>{{ item.nome }}</td>
              <td>{{ item.tipo }}</td>
              <td>
                <a
                  class="remover-item"
                  href="#"
                  @click.prevent="removerItemTabela(item.uuid)"
                  >Remover</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mask } from 'vue-the-mask'; // https://vuejs-tips.github.io/vue-the-mask/
import _findIndex from 'lodash/findIndex'; // https://lodash.com/docs/4.17.15#findIndex
import _join from 'lodash/join'; // https://lodash.com/docs/4.17.15#join
import _replace from 'lodash/replace'; // https://lodash.com/docs/4.17.15#replace

export default {
  directives: {
    mask,
  },
  data: function() {
    return {
      listaTabelaContatos: [],
      textContatoNome: null,
      textContatoEmail: null,
      textContatoCPF: null,
      textContatoTelefone: null,
      textContatoRG: null,
      textContatoTipo: [],
      campoObrigatorio: true,
    };
  },
  methods: {
    verificarCpfValido() {
      var cpfValido = false;

      if (this.textContatoCPF !== '') {
        cpfValido = this.validarCpf(this.textContatoCPF);
      }

      if (!cpfValido) {
        this.$notify({
          group: 'notificacao',
          title: 'Atenção',
          text: `O CPF informado é inválido!`,
          type: 'warn',
        });
      }
    },
    createUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    adicionarItemTabela() {
      let listaErros = this.validarFormulario();

      // console.log(listaErros);
      // console.log(_join(listaErros, ', '));

      if (listaErros.length > 0) {
        this.$notify({
          group: 'notificacao',
          title: 'Atenção',
          text: _join(listaErros, ', '),
          type: 'warn',
        });
        return;
      }
      let uuid = this.createUUID();

      var novoItem = {
        uuid: this.removerCaracteresEspeciais(uuid),
        nome: this.removerCaracteresEspeciais(this.textContatoNome),
        email: this.removerCaracteresEspeciais(this.textContatoEmail),
        cpf: this.removerCaracteresEspeciais(this.textContatoCPF),
        telefone: this.removerCaracteresEspeciais(this.textContatoTelefone),
        rg: this.removerCaracteresEspeciais(this.textContatoRG),
        tipo: this.removerCaracteresEspeciais(this.textContatoTipo),
      };

      this.listaTabelaContatos.push(novoItem);

      this.textContatoNome = null;
      this.textContatoEmail = null;
      this.textContatoCPF = null;
      this.textContatoTelefone = null;
      this.textContatoRG = null;
      this.textContatoTipo = [];
      this.campoObrigatorio = this.listaTabelaContatos.length < 1;
    },
    removerItemTabela(uuid) {
      var indice = _findIndex(this.listaTabelaContatos, function(o) {
        return o.uuid === uuid;
      });

      this.listaTabelaContatos.splice(indice, 1);
      this.campoObrigatorio = this.listaTabelaContatos.length < 1;
    },
    removerCaracteresEspeciais(texto) {
      return _replace(texto, /(\r\n|\n|\r|"|'|`)/g, '').trim();
    },
    validarCpf(cpf) {
      if (cpf === '' || cpf === null) return false;
      cpf = cpf.replace(/[^\d]+/g, '');
      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length != 11 ||
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999'
      )
        return false;
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(9))) return false;
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(10))) return false;
      return true;
    },
    dadosContato() {
      // Método chamado pelo App.vue
      return {
        representantes: this.listaTabelaContatos,
      };
    },
    validarFormulario() {
      let listaErros = [];

      if (this.textContatoTipo.length === 0) {
        listaErros.push('Ao menos um tipo de contato deve ser adicionado');
        return listaErros;
      }

      // Erros para campos obrigatórios
      let max = this.textContatoTipo.length;
      for (let index = 0; index < max; index++) {
        const element = this.textContatoTipo[index];

        // console.log(element);

        if (element.trim() == 'Representante legal') {
          if (this.textContatoNome == null) {
            listaErros.push('Nome obrigatório para Representante legal');
          }

          if (this.textContatoTelefone == null) {
            listaErros.push('Telefone obrigatório para Representante legal');
          }

          if (this.textContatoCPF == null) {
            listaErros.push('CPF obrigatório para Representante legal');
          }
        }

        if (element.trim() == 'Administrador da conta') {
          if (this.textContatoNome == null) {
            listaErros.push('Nome obrigatório para Administrador da conta');
          }

          if (this.textContatoTelefone == null) {
            listaErros.push('Telefone obrigatório para Administrador da conta');
          }

          if (this.textContatoRG == null) {
            listaErros.push('RG obrigatório para Administrador da conta');
          }
        }

        if (element.trim() == 'Responsável por receber os aparelhos') {
          if (this.textContatoNome == null) {
            listaErros.push(
              'Nome obrigatório para Responsável por receber os aparelhos'
            );
          }

          if (this.textContatoTelefone == null) {
            listaErros.push(
              'Telefone obrigatório para Responsável por receber os aparelhos'
            );
          }

          if (this.textContatoCPF == null) {
            listaErros.push(
              'CPF obrigatório para Responsável por receber os aparelhos'
            );
          }

          if (this.textContatoEmail == null) {
            listaErros.push(
              'Email obrigatório para Responsável por receber os aparelhos'
            );
          }
        }
      }

      return listaErros;
    },
  },
};
</script>

<template>
  <div class="tab-pane" id="resumo">
    <div class="row">
      <h4 class="info-text">Resumo</h4>
      <div class="col-sm-12 ">
        <h2>Dados da Empresa</h2>
        <ul class="list-group" v-if="this.empresa != null">
          <li class="list-group-item">
            <strong>CNPJ:</strong> {{ this.empresa.cnpj }}
          </li>
          <li class="list-group-item">
            <strong>Razão Social:</strong> {{ this.empresa.razao_social }}
          </li>
          <li class="list-group-item">
            <strong>Atividade Principal:</strong>
            {{ this.empresa.atividade_principal }}
          </li>
          <li class="list-group-item">
            <strong>Logradouro:</strong> {{ this.empresa.logradouro }}
          </li>
          <li class="list-group-item">
            <strong>Número:</strong> {{ this.empresa.numero }}
          </li>
          <li class="list-group-item">
            <strong>Telefone:</strong> {{ this.empresa.telefone }}
          </li>
          <li class="list-group-item">
            <strong>Inscrição Estadual:</strong>
            {{ this.empresa.inscricao_estadual }}
          </li>
          <li class="list-group-item">
            <strong>Operadora:</strong> {{ this.empresa.operadora }}
          </li>
        </ul>

        <h2>Contatos</h2>
        <div v-if="this.representantes">
          <ul
            class="list-group"
            v-for="(item, index) in this.representantes"
            :key="index"
          >
            <li class="list-group-item">
              <strong>Nome:</strong>
              {{ item.nome }}
            </li>
            <li class="list-group-item">
              <strong>E-mail:</strong>
              {{ item.email }}
            </li>
            <li class="list-group-item">
              <strong>Telefone:</strong>
              {{ item.telefone }}
            </li>
            <li class="list-group-item">
              <strong>CPF:</strong>
              {{ item.cpf }}
            </li>
            <li class="list-group-item">
              <strong>RG:</strong>
              {{ item.rg }}
            </li>
            <li class="list-group-item">
              <strong>Tipo:</strong>
              {{ item.tipo }}
            </li>
          </ul>
        </div>

        <h2>Pedidos</h2>
        <ul class="list-group" v-if="this.pedidos">
          <li
            class="list-group-item"
            v-for="(item, index) in this.pedidos"
            :key="index"
          >
            <ul>
              Número:
              {{
                item.ddd
              }}
              {{
                item.telefone
              }}
            </ul>
            <ul>
              Tipo:
              {{
                item.tipo
              }}
            </ul>
            <ul>
              Operadora:
              {{
                item.operadora
              }}
            </ul>
            <ul>
              Pacote
              {{
                item.pacote_plano
              }}
            </ul>
            <ul>
              Valor: R$&nbsp;
              {{
                truncarValor(item.pacote_valor)
              }}
            </ul>
            <ul>
              Desconto: R$&nbsp;
              {{
                truncarValor(item.pacote_desconto)
              }}
            </ul>
            <ul>
              Valor total: R$&nbsp;
              {{
                truncarValor(item.pacote_valor_total)
              }}
            </ul>
            <ul>
              Convergência (número contrato):
              {{
                item.convergencia
              }}
            </ul>
            <ul>
              Observações do pedido:
              {{
                item.observacao
              }}
            </ul>
          </li>
        </ul>

        <h2>Aparelhos</h2>
        <ul class="list-group" v-if="this.aparelhos">
          <li
            class="list-group-item"
            v-for="(item, index) in this.aparelhos"
            :key="index"
          >
            <ul>
              Fabricante:
              {{
                item.fabricante
              }}
            </ul>
            <ul>
              Modelo:
              {{
                item.gerencial
              }}
            </ul>
            <ul>
              Tecnologia:
              {{
                item.tecnologia
              }}
            </ul>
            <ul>
              SIM Card:
              {{
                item.sim_card
              }}
            </ul>
            <ul>
              Faixa:
              {{
                item.faixa
              }}
            </ul>
            <ul>
              Pontuação:
              {{
                item.pontuacao
              }}
            </ul>
            <ul>
              Valor: R$&nbsp;
              {{
                truncarValor(item.valor)
              }}
            </ul>
            <ul>
              Desconto: R$&nbsp;
              {{
                truncarValor(item.desconto)
              }}
            </ul>
            <ul>
              Valor total: R$&nbsp;
              {{
                truncarValor(item.total)
              }}
            </ul>
          </li>
        </ul>

        <h2>Observações</h2>
        <ul class="list-group" v-if="this.observacao">
          <li class="list-group-item">
            <ul
              v-for="linha in observacao.observacoes.observacao.split('\n')"
              :key="linha"
            >
              {{
                linha
              }}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      empresa: null,
      representantes: null,
      pedidos: null,
      receitaWS: null,
      aparelhos: null,
      observacao: null,
    };
  },
  methods: {
    carregadDadosApresentacaoResumo(
      empresa,
      contato,
      pedido,
      receitaWS,
      aparelhos,
      observacoes
    ) {
      this.empresa = empresa.empresa;
      this.representantes = contato.representantes;
      this.pedidos = pedido.pedidos;
      this.receitaWS = receitaWS;
      this.aparelhos = aparelhos.aparelhos;
      this.observacao = observacoes;

      // console.log('----------------RESUMO----------------');
      // console.log('>>> Empresa');
      // console.log(empresa);
      // console.log('>>> Contato');
      // console.log(contato);
      // console.log('>>> Pedido');
      // console.log(pedido);
      // console.log('>>> Receita WS');
      // console.log(receitaWS);
      // console.log('>>> Aparelhos');
      // console.log(aparelhos);
      // console.log('>>> Observações');
      // console.log(observacoes);
      // console.log('>>> THIS Observações');
      // console.log(this.observacao);
    },
    truncarValor(valor) {
      return valor === (valor === undefined || valor === null || valor === '')
        ? 0
        : parseFloat(valor).toFixed(2);
    },
  },
};
</script>

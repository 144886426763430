<template>
  <div class="tab-pane" id="aparelhos">
    <div class="row">
      <h4 class="info-text">Lista de Aparelhos</h4>
    </div>
    <div class="col-sm-12">
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <input
            type="checkbox"
            class="form-check-input"
            @click="permitirSemEquipamentos"
            v-model="semEquipamentos"
          />
          <label class="control-label fonte-maior">SEM EQUIPAMENTOS</label>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Tipo do pedido</label>
          <select
            id="selectAparelhos"
            name="selectAparelhos"
            class="form-control"
            tabindex="24"
            :required="campoObrigatorio"
            v-model="selectAparelhos"
          >
            <option disabled="" selected=""></option>
            <option
              v-for="(item, index) in listaAparelhos"
              :key="index"
              :value="item.idAparelho"
              >{{ item.gerencial }}</option
            >
          </select>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value="0"
            @click="carregarItemSelecionado"
          />
          <label
            class="control-label fonte-maior"
            for="inlineRadio1"
            style="margin: 0 0 0 10px;"
            >Faixa</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            value="1"
            @click="carregarItemSelecionado"
          />
          <label
            class="control-label fonte-maior"
            for="inlineRadio2"
            style="margin: 0 0 0 10px;"
            >Pontuação</label
          >
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Valor do aparelho</label>
          <input
            id="textValorAparelho"
            name="textValorAparelho"
            type="text"
            class="form-control"
            tabindex="25"
            :required="campoObrigatorio"
            v-money="money"
            v-model.lazy="textValorAparelho"
          />
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="input-group" v-if="itemSelecionado === 0">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Faixa</label>
          <select
            id="selectFaixa"
            name="selectFaixa"
            class="form-control"
            tabindex="33"
            v-model="selectFaixa"
          >
            <option
              v-for="(item, index) in listaFaixa"
              :key="index"
              :value="item"
              >{{ item }}</option
            >
          </select>
        </div>
      </div>
      <div class="input-group" v-if="itemSelecionado === 1">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Pontuação</label>
          <select
            id="selectPontuacao"
            name="selectPontuacao"
            class="form-control"
            tabindex="34"
            v-model="selectPontuacao"
          >
            <option
              v-for="(item, index) in listaPontuacao"
              :key="index"
              :value="item"
              >{{ item }}</option
            >
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="pull-right">
          <div class="input-group">
            <input
              type="button"
              class="btn btn-danger btn-wd"
              id="buttonAdicionar"
              value="Adicionar item"
              @click="adicionarItemTabela"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <table class="table" id="tableAparelhos">
          <thead>
            <tr>
              <th scope="col">Fabricante</th>
              <th scope="col">Aparelho</th>
              <th scope="col">Tecnologia</th>
              <th scope="col">SIM Card</th>
              <th scope="col">Valor</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody id="dataTable">
            <tr v-for="(item, index) in listaTabelaAparelhos" :key="index">
              <td>{{ item.fabricante }}</td>
              <td>{{ item.gerencial }}</td>
              <td>{{ item.tecnologia }}</td>
              <td>{{ item.sim_card }}</td>
              <td>{{ item.total }}</td>
              <td>
                <a
                  class="remover-item"
                  href="#"
                  @click.prevent="removerItemTabela(item.uuid)"
                  >Remover</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-if="this.listaTabelaAparelhos.length">
      <div class="col-sm-6">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">Aplicar desconto geral</label>
            <input
              id="textDesconto"
              name="textDesconto"
              type="tel"
              class="form-control"
              maxlength="11"
              tabindex="26"
              v-money="money"
              v-model.lazy="textDesconto"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="pull-right">
          <div class="input-group">
            <input
              type="button"
              class="btn btn-danger btn-wd"
              id="buttonAplicarDesconto"
              value="Atualizar valores"
              @click="atualizarValores"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { VMoney } from 'v-money';
import _find from 'lodash/find'; // https://lodash.com/docs/4.17.15#find
import _replace from 'lodash/replace'; // https://lodash.com/docs/4.17.15#replace
import _findIndex from 'lodash/findIndex'; // https://lodash.com/docs/4.17.15#findIndex
import _join from 'lodash/join'; // https://lodash.com/docs/4.17.15#join

export default {
  directives: {
    money: VMoney,
  },
  mounted() {
    this.init();
  },
  data: function() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      selectAparelhos: null,
      selectFaixa: null,
      selectPontuacao: null,
      textValorAparelho: null,
      listaAparelhos: [],
      listaTabelaAparelhos: [],
      listaFaixa: ['', '5GB', '7GB', '10GB', '15GB', '20GB', '50GB', '80GB'],
      listaPontuacao: ['', '100', '140', '200', '250', '300'],
      campoObrigatorio: true,
      semEquipamentos: false,
      itemSelecionado: null,
    };
  },
  methods: {
    init() {
      this.carregarAparelhos();
    },
    createUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    carregarAparelhos() {
      var url = 'server/carregar_lista_aparelhos.php';
      axios
        .post(url)
        .then((resposta) => this.respostaAparelhos(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    respostaAparelhos(data) {
      var item = {},
        lista = [];

      if (data !== null) {
        for (let key in data) {
          item = {
            idAparelho: parseInt(data[key].ID_APARELHO),
            fabricante: data[key].FABRICANTE,
            codigo: data[key].CODIGO,
            gerencial: data[key].GERENCIAL,
            tecnologia: data[key].TECNOLOGIA,
            simCard: data[key].SIMCARD,
            valor: 0,
            desconto: 0,
            total: 0,
            faixa: '',
            pontuacao: '',
          };

          lista.push(item);
        }
      }

      this.listaAparelhos = lista;
    },
    adicionarItemTabela() {
      let listaErros = this.validarFormulario();

      // console.log(listaErros);
      // console.log(_join(listaErros, ', '));

      if (listaErros.length > 0) {
        this.$notify({
          group: 'notificacao',
          title: 'Atenção',
          text: _join(listaErros, ', '),
          type: 'warn',
        });
        return;
      }

      var aparelhoId = parseInt(this.selectAparelhos),
        aparelho = _find(this.listaAparelhos, function(o) {
          return o.idAparelho == aparelhoId;
        }),
        valorAparelho =
          this.textValorAparelho === ('' || null)
            ? 0
            : parseFloat(
                _replace(
                  _replace(this.textValorAparelho, /[R$ .]+/g, ''),
                  /[,]+/g,
                  '.'
                )
              ).toFixed(2),
        uuid = this.createUUID();

      var novoItem = {
        uuid: uuid,
        id_aparelho: aparelho.idAparelho,
        fabricante: aparelho.fabricante,
        codigo: aparelho.codigo,
        gerencial: aparelho.gerencial,
        tecnologia: aparelho.tecnologia,
        sim_card: aparelho.simCard,
        valor: valorAparelho,
        desconto: 0,
        total: valorAparelho,
        faixa: this.selectFaixa ?? '',
        pontuacao: this.selectPontuacao ?? '',
      };

      this.listaTabelaAparelhos.push(novoItem);
      this.selectAparelhos = null;
      this.textValorAparelho = null;
      this.campoObrigatorio = this.listaTabelaAparelhos.length < 1;
    },
    removerItemTabela(uuid) {
      var indice = _findIndex(this.listaTabelaAparelhos, function(o) {
        return o.uuid === uuid;
      });

      this.listaTabelaAparelhos.splice(indice, 1);
      this.campoObrigatorio = this.listaTabelaAparelhos.length < 1;
    },
    atualizarValores() {
      var item = {},
        lista = [],
        desconto =
          this.textDesconto == null || this.textDesconto == ''
            ? 0
            : parseFloat(
                _replace(
                  _replace(this.textDesconto, /[R$ .]+/g, ''),
                  /[,]+/g,
                  '.'
                )
              ).toFixed(2),
        data = this.listaTabelaAparelhos;

      if (data !== null) {
        for (let key in data) {
          var valor = data[key].valor,
            total = valor - desconto;

          item = {
            uuid: this.removerCaracteresEspeciais(data[key].uuid),
            id_aparelho: this.removerCaracteresEspeciais(data[key].id_aparelho),
            fabricante: this.removerCaracteresEspeciais(data[key].fabricante),
            codigo: this.removerCaracteresEspeciais(data[key].codigo),
            gerencial: this.removerCaracteresEspeciais(data[key].gerencial),
            tecnologia: this.removerCaracteresEspeciais(data[key].tecnologia),
            sim_card: this.removerCaracteresEspeciais(data[key].sim_card),
            valor: this.removerCaracteresEspeciais(valor),
            desconto: this.removerCaracteresEspeciais(desconto),
            total: this.removerCaracteresEspeciais(total),
            faixa: this.removerCaracteresEspeciais(data[key].faixa),
            pontuacao: this.removerCaracteresEspeciais(data[key].pontuacao),
          };

          lista.push(item);
        }
      }

      this.listaTabelaAparelhos = lista;
    },
    removerCaracteresEspeciais(texto) {
      return _replace(texto, /(\r\n|\n|\r|"|'|`)/g, '').trim();
    },
    dadosAparelhos() {
      // Método chamado pelo App.vue
      return {
        aparelhos: this.listaTabelaAparelhos,
      };
    },
    permitirSemEquipamentos() {
      this.campoObrigatorio = this.semEquipamentos;
    },
    carregarItemSelecionado(item) {
      if (
        item.target.value !== undefined &&
        item.target.value !== null &&
        item.target.value !== ''
      ) {
        this.itemSelecionado = parseInt(item.target.value);

        if (this.itemSelecionado === 0) {
          // Faixa
          this.selectPontuacao = null;
        }

        if (this.itemSelecionado === 1) {
          // Pontuação
          this.selectFaixa = null;
        }

        return;
      }

      this.itemSelecionado = null;
    },
    validarFormulario() {
      let erros = [];

      if (this.itemSelecionado === null) {
        erros.push(
          'Selecione ao menos uma das opções: Faixa ou Pontuação e selecione uma condição.'
        );
      }

      if (this.selectAparelhos === ('' || null)) {
        erros.push('Selecione ao menos um aparelho');
      }

      if (this.textValorAparelho === ('' || null)) {
        erros.push('Adicione um valor para o aparelho');
      }

      return erros;
    },
  },
};
</script>
<style scoped>
.fonte-maior {
  font-size: 17px !important;
}
</style>

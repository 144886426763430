<template>
  <div class="mt-3 mb-3">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Remover pacote</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
          <router-link
            class="btn btn-sm btn-outline-secondary"
            to="/pacotes/listar"
            >VOLTAR</router-link
          >
        </div>
      </div>
    </div>
    <div class="row" v-if="dadosCarregados">
      <div class="col-md-12 order-md-1">
        <form class="needs-validation" novalidate="">
          <div class="row">
            <div class="col-md-2 mb-6">
              <label for="textoOrdem">Ordem</label>
              <input
                type="text"
                class="form-control"
                placeholder="Ordem"
                maxlength="2"
                disabled
                v-model="textoOrdem"
              />
            </div>
            <div class="col-md-10 mb-6">
              <label for="textoDescricao">Descrição</label>
              <input
                type="text"
                class="form-control"
                placeholder="Descrição"
                maxlength="100"
                disabled
                v-model="textoDescricao"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-4">
              <label for="textoDesconto">Possui desconto</label>
              <input
                type="text"
                class="form-control"
                placeholder="1 ou 0"
                maxlength="1"
                disabled
                v-model="textoDesconto"
              />
            </div>
            <div class="col-md-4 mb-4">
              <label for="textoDesabilitado">Desabilitado</label>
              <input
                type="text"
                class="form-control"
                placeholder="1 ou 0"
                maxlength="1"
                disabled
                v-model="textoDesabilitado"
              />
            </div>
            <div class="col-md-4 mb-4">
              <label for="textoValor">Valor</label>
              <input
                type="tel"
                class="form-control"
                placeholder="Código"
                maxlength="100"
                disabled
                v-money="money"
                v-model.lazy="textoValor"
              />
            </div>
          </div>
          <hr class="mb-4" />
          <button
            class="btn btn-primary btn-lg btn-block"
            type="button"
            @click="remover"
          >
            Salvar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { VMoney } from 'v-money';
import _replace from 'lodash/replace'; // https://lodash.com/docs/4.17.15#replace

export default {
  directives: {
    money: VMoney,
  },
  mounted() {
    this.init();
  },
  data: function() {
    return {
      id: null,
      dadosCarregados: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      textoOrdem: null,
      textoDescricao: null,
      textoDesconto: null,
      textoDesabilitado: null,
      textoValor: null,
    };
  },
  methods: {
    init() {
      this.id = this.$route.params.id;
      this.carregar(this.id);
    },
    carregar(id) {
      var url = 'server/pacotes/carregar.php',
        params = new URLSearchParams();

      params.append('id', id);

      axios
        .post(url, params)
        .then((resposta) => this.respostaCarregar(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    respostaCarregar(data) {
      this.textoOrdem = data[0].ORDEM;
      this.textoDescricao = data[0].PLANO;
      this.textoDesconto = data[0].DESCONTO_APLICAVEL;
      this.textoDesabilitado = data[0].DISABLED;
      this.textoValor = `R$ ${_replace(data[0].VALOR, /[.]+/g, ',')}`;
      this.dadosCarregados = true;
    },
    remover() {
      const options = {
        title: 'Confirmar exclusão?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
      };
      this.$swal(options).then((resultado) => {
        if (resultado.isConfirmed) {
          var url = 'server/pacotes/remover.php',
            params = new URLSearchParams();

          params.append('id', this.id);

          axios
            .post(url, params)
            .then((resposta) => this.respostaRemover(resposta.data))
            .catch((erro) => console.error(erro));
        } else if (resultado.isDenied) {
          return;
        }
      });

      return;
    },
    // eslint-disable-next-line no-unused-vars
    respostaRemover(data) {
      const options = {
        icon: 'success',
        title: 'Dados removidos com sucesso',
        showConfirmButton: false,
        showCloseButton: true,
        timer: 2500,
      };
      this.$swal(options);
      window.setTimeout(() => {
        this.$router.push('/pacotes/listar');
      }, 3000);
    },
  },
};
</script>

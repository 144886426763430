<template>
  <div class="mt-3 mb-3">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Inserir novo pacote</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
          <router-link
            class="btn btn-sm btn-outline-secondary"
            to="/pacotes/listar"
            >VOLTAR</router-link
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 order-md-1">
        <form class="needs-validation" novalidate="">
          <div class="row">
            <div class="col-md-2 mb-6">
              <label for="textoOrdem">Ordem</label>
              <input
                type="text"
                class="form-control"
                placeholder="Ordem"
                maxlength="2"
                required
                v-model="textoOrdem"
              />
            </div>
            <div class="col-md-10 mb-6">
              <label for="textoDescricao">Descrição</label>
              <input
                type="text"
                class="form-control"
                placeholder="Descrição"
                maxlength="100"
                required
                v-model="textoDescricao"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-4">
              <label for="textoDesconto">Possui desconto</label>
              <input
                type="text"
                class="form-control"
                placeholder="1 ou 0"
                maxlength="1"
                required
                v-model="textoDesconto"
              />
            </div>
            <div class="col-md-4 mb-4">
              <label for="textoDesabilitado">Desabilitado</label>
              <input
                type="text"
                class="form-control"
                placeholder="1 ou 0"
                maxlength="1"
                required
                v-model="textoDesabilitado"
              />
            </div>
            <div class="col-md-4 mb-4">
              <label for="textoValor">Valor</label>
              <input
                type="tel"
                class="form-control"
                placeholder="Código"
                maxlength="100"
                required
                v-money="money"
                v-model.lazy="textoValor"
              />
            </div>
          </div>
          <hr class="mb-4" />
          <button
            class="btn btn-primary btn-lg btn-block"
            type="button"
            @click="inserir"
          >
            Salvar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { VMoney } from 'v-money';
import _replace from 'lodash/replace'; // https://lodash.com/docs/4.17.15#replace

export default {
  directives: {
    money: VMoney,
  },
  data: function() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      textoOrdem: null,
      textoDescricao: null,
      textoDesconto: null,
      textoDesabilitado: null,
      textoValor: null,
    };
  },
  methods: {
    inserir() {
      let formularioValido = this.validarFormulario();

      if (formularioValido !== '') {
        const options = {
          icon: 'error',
          title: 'Oops...',
          text: formularioValido,
          showConfirmButton: false,
          timer: 2500,
        };
        this.$swal(options);
        return;
      }

      var url = 'server/pacotes/inserir.php',
        params = new URLSearchParams();

      let textoValor =
        this.textoValor === ('' || null)
          ? 0
          : parseFloat(
              _replace(
                _replace(this.textoValor, /[R$ .]+/g, ''),
                /[,]+/g,
                '.'
              )
            ).toFixed(2);

      params.append('textoOrdem', this.textoOrdem);
      params.append('textoDescricao', this.textoDescricao);
      params.append('textoDesconto', this.textoDesconto);
      params.append('textoDesabilitado', this.textoDesabilitado);
      params.append('textoValor', textoValor);

      axios
        .post(url, params)
        .then((resposta) => this.respostaInserir(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    // eslint-disable-next-line no-unused-vars
    respostaInserir(data) {
      const options = {
        icon: 'success',
        title: 'Dados salvos com sucesso',
        showConfirmButton: false,
        showCloseButton: true,
        timer: 2500,
      };
      this.$swal(options);
      window.setTimeout(() => {
        this.$router.push('/pacotes/listar');
      }, 3000);
    },
    validarFormulario() {
      if (this.textoOrdem === null) {
        return 'O campo "Ordem" é obrigatório.';
      }

      if (this.textoDescricao === null) {
        return 'O campo "Descrição" é obrigatório.';
      }

      if (this.textoDesconto === null) {
        return 'O campo "Possui desconto" é obrigatório.';
      }

      if (this.textoDesabilitado === null) {
        return 'O campo "Desabilitado" é obrigatório.';
      }

      if (this.textoValor === null) {
        return 'O campo "Valor" é obrigatório.';
      }

      return '';
    },
  },
};
</script>

<template>
  <div class="tab-pane" id="pedido">
    <div class="row">
      <h4 class="info-text">Lista de Pedidos</h4>
    </div>
    <div class="col-sm-6">
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Tipo do pedido</label>
          <select
            id="selectPedidoTipo"
            name="selectPedidoTipo"
            class="form-control"
            tabindex="27"
            :required="campoObrigatorio"
            v-model="selectPedidoTipo"
            @change="verificarBloqueioCampos"
          >
            <option disabled="" selected=""></option>
            <option
              v-for="(item, index) in listaPedidos"
              :key="index"
              :value="item"
              >{{ item }}</option
            >
          </select>
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">DDD</label>
          <input
            id="textPedidoDDD"
            name="textPedidoDDD"
            type="tel"
            class="form-control"
            tabindex="29"
            v-mask="'(##)'"
            v-model="textPedidoDDD"
          />
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Pacote</label>
          <select
            id="selectPedidoPacote"
            name="selectPedidoPacote"
            class="form-control"
            tabindex="31"
            :required="campoObrigatorio"
            v-model="selectPedidoPacote"
            @change="atualizarValorPacoteSelecionado"
          >
            <option
              v-for="(item, index) in listaPacotes"
              :key="index"
              :value="item.idPacote"
              :disabled="item.disabled"
              >{{ item.plano }}</option
            >
          </select>
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Convergência (número contrato)</label>
          <input
            id="textConvergencia"
            name="textConvergencia"
            type="text"
            class="form-control"
            tabindex="32"
            maxlength="15"
            v-model="textConvergencia"
          />
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Repetições (padrão 0)</label>
          <input
            id="textRepeticoes"
            name="textRepeticoes"
            type="tel"
            class="form-control"
            tabindex="35"
            maxlength="2"
            v-mask="'##'"
            v-model="textRepeticoes"
          />
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Operadora</label>
          <select
            id="selectPedidoOperadora"
            name="selectPedidoOperadora"
            class="form-control"
            tabindex="28"
            v-model="selectPedidoOperadora"
          >
            <option disabled="" selected=""></option>
            <option
              v-for="(item, index) in listaOperadoras"
              :key="index"
              :value="item"
              >{{ item }}</option
            >
          </select>
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Telefone</label>
          <input
            id="textPedidoTelefone"
            name="textPedidoTelefone"
            type="tel"
            class="form-control"
            tabindex="30"
            v-mask="['####-####', '#####-####']"
            v-model="textPedidoTelefone"
          />
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">&nbsp;</label>
          <input
            id="textPedidoValor"
            name="textPedidoValor"
            type="text"
            class="form-control"
            readonly
            tabindex="32"
            v-model="textPedidoValor"
          />
        </div>
      </div>
      <div class="input-group">
        <span class="input-group-addon"> </span>
        <div class="form-group label-floating">
          <label class="control-label">Observação</label>
          <input
            id="textObservacao"
            name="textObservacao"
            type="text"
            class="form-control"
            tabindex="32"
            maxlength="200"
            v-model="textObservacao"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="pull-right">
          <div class="input-group">
            <input
              type="button"
              class="btn btn-danger btn-wd"
              id="buttonAdicionar"
              value="Adicionar item"
              @click="adicionarItemTabela"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <table class="table" id="tablePedidos">
          <thead>
            <tr>
              <th scope="col">DDD</th>
              <th scope="col">Número</th>
              <th scope="col">Tipo do pedido</th>
              <th scope="col">Operadora</th>
              <th scope="col">Pacote</th>
              <th scope="col">Valor</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody id="dataTable">
            <tr v-for="(item, index) in listaTabelaPedidos" :key="index">
              <td>{{ item.ddd }}</td>
              <td>{{ item.telefone }}</td>
              <td>{{ item.tipo }}</td>
              <td>{{ item.operadora }}</td>
              <td>{{ item.pacote_plano }}</td>
              <td>{{ apresentarDecimalPtBr(item.pacote_valor_total) }}</td>
              <td>
                <a
                  class="remover-item"
                  href="#"
                  @click.prevent="removerItemTabela(item.uuid)"
                  >Remover</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-if="this.listaTabelaPedidos.length">
      <div class="col-sm-6">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">Aplicar desconto geral</label>
            <input
              id="textDesconto"
              name="textDesconto"
              type="tel"
              class="form-control"
              maxlength="11"
              tabindex="36"
              v-money="money"
              v-model.lazy="textDesconto"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="pull-right">
          <div class="input-group">
            <input
              type="button"
              class="btn btn-danger btn-wd"
              id="buttonAplicarDesconto"
              value="Atualizar valores"
              @click="atualizarValores"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mask } from 'vue-the-mask'; // https://vuejs-tips.github.io/vue-the-mask/
import { VMoney } from 'v-money';
import _find from 'lodash/find'; // https://lodash.com/docs/4.17.15#find
import _replace from 'lodash/replace'; // https://lodash.com/docs/4.17.15#replace
import _findIndex from 'lodash/findIndex'; // https://lodash.com/docs/4.17.15#findIndex
// https://github.com/euvl/vue-notification/

export default {
  directives: {
    mask,
    money: VMoney,
  },
  mounted() {
    this.init();
  },
  data: function() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      listaPedidos: [
        'Novo',
        'Portabilidade',
        'Incremento',
        'Renovação',
        'TT PJ/PJ',
        'TT PF/PJ',
      ],
      listaOperadoras: ['Linha nova', 'Claro', 'Vivo', 'Tim', 'Oi', 'Nextel'],
      listaPacotes: [],
      listaTabelaPedidos: [],
      textPedidoDDD: null,
      selectPedidoTipo: null,
      selectPedidoPacote: null,
      selectPedidoOperadora: null,
      textPedidoTelefone: null,
      textPedidoValor: null,
      textValorDesconto: null,
      textDesconto: null,
      textConvergencia: null,
      textObservacao: null,
      campoObrigatorio: true,
      textRepeticoes: null,
      validarCampos: true,
    };
  },
  methods: {
    init() {
      this.carregarPacotes();
    },
    createUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    carregarPacotes() {
      var url = 'server/carregar_lista_pacotes.php';
      axios
        .post(url)
        .then((resposta) => this.respostaPacotes(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    respostaPacotes(data) {
      var item = {},
        lista = [];

      if (data !== null) {
        for (let key in data) {
          item = {
            idPacote: parseInt(data[key].ID_PACOTE),
            ordem: parseInt(data[key].ORDEM),
            plano: data[key].PLANO,
            descontoAplicavel:
              data[key].DESCONTO_APLICAVEL == '0' ? false : true,
            disabled: data[key].DISABLED == '0' ? false : true,
            valor: parseFloat(data[key].VALOR).toFixed(2),
          };

          lista.push(item);
        }
      }

      this.listaPacotes = lista;
      // console.log(this.listaPacotes);
    },
    adicionarItemTabela() {
      if (
        (this.validarCampos &&
          (this.textPedidoDDD === ('' || null) ||
            this.textPedidoTelefone === ('' || null))) ||
        this.selectPedidoTipo === ('' || null) ||
        this.selectPedidoOperadora === ('' || null) ||
        this.selectPedidoPacote === ('' || null)
      ) {
        this.$notify({
          group: 'notificacao',
          title: 'Atenção',
          text: 'Preencha todos os campos para adicionar uma nova linha',
          type: 'warn',
        });
        return;
      }

      var pacoteId = parseInt(this.selectPedidoPacote),
        pacote = _find(this.listaPacotes, function(o) {
          return o.idPacote == pacoteId;
        }),
        valorDesconto =
          this.textValorDesconto === ('' || null)
            ? 0
            : parseFloat(
                _replace(this.textValorDesconto, /[,]+/g, '.')
              ).toFixed(2),
        uuid = this.createUUID();

      var novoItem = {
        uuid: uuid,
        ddd: this.textPedidoDDD,
        telefone: this.textPedidoTelefone,
        tipo: this.selectPedidoTipo,
        operadora: this.selectPedidoOperadora,
        desconto_aplicavel: pacote.descontoAplicavel,
        pacote_id: pacoteId,
        pacote_plano: pacote.plano,
        pacote_valor: pacote.valor,
        pacote_desconto: valorDesconto,
        pacote_valor_total: pacote.valor - valorDesconto,
        convergencia: this.textConvergencia ?? '',
        observacao: this.textObservacao ?? '',
      };

      this.listaTabelaPedidos.push(novoItem);

      if (this.textRepeticoes !== null) {
        let repeticoes = parseInt(this.textRepeticoes);

        if (repeticoes > 0) {
          for (let index = 0; index < repeticoes; index++) {
            this.listaTabelaPedidos.push(novoItem);
          }
        }
      }

      this.textPedidoDDD = null;
      this.textPedidoTelefone = null;
      this.selectPedidoTipo = null;
      this.selectPedidoOperadora = null;
      this.selectPedidoPacote = null;
      this.textConvergencia = null;
      this.textObservacao = null;
      this.textPedidoValor = null;
      this.textRepeticoes = null;
      this.campoObrigatorio = this.listaTabelaPedidos.length < 1;
    },
    removerItemTabela(uuid) {
      var indice = _findIndex(this.listaTabelaPedidos, function(o) {
        return o.uuid === uuid;
      });

      this.listaTabelaPedidos.splice(indice, 1);
      this.campoObrigatorio = this.listaTabelaPedidos.length < 1;
    },
    atualizarValorPacoteSelecionado(event) {
      var pacoteId = parseInt(event.target.value),
        pacote = _find(this.listaPacotes, function(o) {
          return o.idPacote == pacoteId;
        });
      // console.log(pacote);
      this.textPedidoValor = `R$ ${_replace(pacote.valor, /[.]+/g, ',')}`;
    },
    dadosPedido() {
      // Método chamado pelo App.vue
      return {
        pedidos: this.listaTabelaPedidos,
      };
    },
    atualizarValores() {
      var item = {},
        lista = [],
        desconto =
          this.textDesconto == null || this.textDesconto == ''
            ? 0
            : parseFloat(
                _replace(
                  _replace(this.textDesconto, /[R$ .]+/g, ''),
                  /[,]+/g,
                  '.'
                )
              ).toFixed(2),
        data = this.listaTabelaPedidos;

      if (data !== null) {
        for (let key in data) {
          var descontoAplicavel = data[key].desconto_aplicavel,
            valorPlano = data[key].pacote_valor,
            valorDesconto = descontoAplicavel ? desconto : 0,
            total = descontoAplicavel ? valorPlano - valorDesconto : valorPlano;

          item = {
            uuid: this.removerCaracteresEspeciais(data[key].uuid),
            ddd: this.removerCaracteresEspeciais(data[key].ddd),
            telefone: this.removerCaracteresEspeciais(data[key].telefone),
            tipo: this.removerCaracteresEspeciais(data[key].tipo),
            operadora: this.removerCaracteresEspeciais(data[key].operadora),
            desconto_aplicavel: this.removerCaracteresEspeciais(
              descontoAplicavel
            ),
            pacote_id: this.removerCaracteresEspeciais(data[key].pacote_id),
            pacote_plano: this.removerCaracteresEspeciais(
              data[key].pacote_plano
            ),
            pacote_valor: this.removerCaracteresEspeciais(valorPlano),
            pacote_desconto: this.removerCaracteresEspeciais(valorDesconto),
            pacote_valor_total: this.removerCaracteresEspeciais(total),
            convergencia: this.removerCaracteresEspeciais(
              data[key].convergencia
            ),
            observacao: this.removerCaracteresEspeciais(data[key].observacao),
          };

          lista.push(item);
        }
      }

      this.listaTabelaPedidos = lista;
    },
    removerCaracteresEspeciais(texto) {
      return _replace(texto, /(\r\n|\n|\r|"|'|`)/g, '').trim();
    },
    apresentarDecimalPtBr(valor) {
      return `R$ ${_replace(parseFloat(valor).toFixed(2), /[.]+/g, ',')}`;
    },
    verificarBloqueioCampos() {
      this.validarCampos = !(this.selectPedidoTipo == 'Novo');
    },
  },
};
</script>

<template>
  <div class="tab-pane" id="observacoes">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="info-text">
          Coloque as suas observações gerais aqui.
        </h4>
      </div>
      <div class="col-sm-12">
        <div class="input-group">
          <span class="input-group-addon"> </span>
          <div class="form-group label-floating">
            <label class="control-label">Observações gerais</label>
            <textarea
              id="textObservacoesGerais"
              name="textObservacoesGerais"
              type="text"
              class="form-control"
              tabindex="1"
              rows="15"
              v-model="textObservacoesGerais"
            >
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _replace from 'lodash/replace'; // https://lodash.com/docs/4.17.15#replace

export default {
  data: function() {
    return {
      textObservacoesGerais: null,
    };
  },
  methods: {
    dadosObservacoes() {
      // Método chamado pelo App.vue
      return {
        observacoes: {
          observacao: this.removerCaracteresEspeciais(
            this.textObservacoesGerais
          ),
        },
      };
    },
    removerCaracteresEspeciais(texto) {
      return _replace(texto, /(\r\n|\n|\r|"|'|`)/g, '').trim();
    },
  },
};
</script>

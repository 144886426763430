<template>
  <div class="mt-3 mb-3">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Editar tipo de aparelho</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
          <router-link
            class="btn btn-sm btn-outline-secondary"
            to="/tipo-aparelho/listar"
            >VOLTAR</router-link
          >
        </div>
      </div>
    </div>
    <div class="row" v-if="dadosCarregados">
      <div class="col-md-12 order-md-1">
        <form class="needs-validation" novalidate="">
          <div class="row">
            <div class="col-md-4 mb-6">
              <label for="textoFabricante">Fabricante</label>
              <input
                type="text"
                class="form-control"
                placeholder="Fabricante"
                maxlength="100"
                required
                v-model="textoFabricante"
              />
            </div>
            <div class="col-md-8 mb-6">
              <label for="textoDescricao">Descrição</label>
              <input
                type="text"
                class="form-control"
                placeholder="Descrição"
                maxlength="100"
                required
                v-model="textoDescricao"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-6">
              <label for="textoCodigo">Código</label>
              <input
                type="text"
                class="form-control"
                placeholder="Código"
                maxlength="100"
                required
                v-model="textoCodigo"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-6">
              <label for="textoTecnologia">Tecnologia</label>
              <input
                type="text"
                class="form-control"
                placeholder="Tecnologia"
                maxlength="45"
                required
                v-model="textoTecnologia"
              />
            </div>
            <div class="col-md-6 mb-6">
              <label for="textoSimcard">SIM Card</label>
              <input
                type="text"
                class="form-control"
                placeholder="SIM Card"
                maxlength="45"
                required
                v-model="textoSimcard"
              />
            </div>
          </div>
          <hr class="mb-4" />
          <button
            class="btn btn-primary btn-lg btn-block"
            type="button"
            @click="editar"
          >
            Salvar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  mounted() {
    this.init();
  },
  data: function() {
    return {
      id: null,
      dadosCarregados: false,
      textoFabricante: null,
      textoDescricao: null,
      textoCodigo: null,
      textoTecnologia: null,
      textoSimcard: null,
    };
  },
  methods: {
    init() {
      this.id = this.$route.params.id;
      this.carregar(this.id);
    },
    carregar(id) {
      var url = 'server/tipo_aparelho/carregar.php',
        params = new URLSearchParams();

      params.append('id', id);

      axios
        .post(url, params)
        .then((resposta) => this.respostaCarregar(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    respostaCarregar(data) {
      this.textoFabricante = data[0].FABRICANTE;
      this.textoDescricao = data[0].GERENCIAL;
      this.textoCodigo = data[0].CODIGO;
      this.textoTecnologia = data[0].TECNOLOGIA;
      this.textoSimcard = data[0].SIMCARD;
      this.dadosCarregados = true;
    },
    editar() {
      let formularioValido = this.validarFormulario();

      if (formularioValido !== '') {
        const options = {
          icon: 'error',
          title: 'Oops...',
          text: formularioValido,
          showConfirmButton: false,
          timer: 2500,
        };
        this.$swal(options);
        return;
      }

      var url = 'server/tipo_aparelho/editar.php',
        params = new URLSearchParams();

      params.append('id', this.id);
      params.append('textoFabricante', this.textoFabricante);
      params.append('textoDescricao', this.textoDescricao);
      params.append('textoCodigo', this.textoCodigo);
      params.append('textoTecnologia', this.textoTecnologia);
      params.append('textoSimcard', this.textoSimcard);

      axios
        .post(url, params)
        .then((resposta) => this.respostaEditar(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    // eslint-disable-next-line no-unused-vars
    respostaEditar(data) {
      const options = {
        icon: 'success',
        title: 'Dados salvos com sucesso',
        showConfirmButton: false,
        showCloseButton: true,
        timer: 2500,
      };
      this.$swal(options);
      window.setTimeout(() => {
        this.$router.push('/tipo-aparelho/listar');
      }, 3000);
    },
    validarFormulario() {
      if (this.textoFabricante === null) {
        return 'O campo "Fabricante" é obrigatório.';
      }

      if (this.textoDescricao === null) {
        return 'O campo "Descrição" é obrigatório.';
      }

      if (this.textoCodigo === null) {
        return 'O campo "Código" é obrigatório.';
      }

      if (this.textoTecnologia === null) {
        return 'O campo "Tecnologia" é obrigatório.';
      }

      if (this.textoSimcard === null) {
        return 'O campo "SIM Card" é obrigatório.';
      }

      return '';
    },
  },
};
</script>

<template>
  <div id="app">
    <div
      class="image-container set-full-height"
      style="background-image: url('assets/img/background.jpg'); background-repeat: no-repeat;
  background-attachment: fixed;"
    >
      <a href="https://www.netcommbrasil.com.br/">
        <div class="logo-container">
          <div style="width: 100px; height: auto;">
            <img
              src="assets/img/netcomm.png"
              style="width: 300px; height: auto;"
            />
          </div>
        </div>
      </a>
      <div class="container">
        <div class="row">
          <div class="col-sm-10 col-sm-offset-1">
            <div class="wizard-container">
              <div class="card wizard-card" data-color="red" id="wizard">
                <form action="cadastrar_pedido.php" method="post">
                  <div class="wizard-header">
                    <h3 class="wizard-title">
                      Netcomm
                    </h3>
                    <h5>Preencha o fomulário para conhecermos você</h5>
                  </div>
                  <div class="wizard-navigation">
                    <ul ref="abas" id="lista-abas">
                      <li>
                        <a
                          href="#empresa"
                          data-toggle="tab"
                          @click="carregarAbaAtiva"
                          >Dados da empresa</a
                        >
                      </li>
                      <li>
                        <a
                          href="#contato"
                          data-toggle="tab"
                          @click="carregarAbaAtiva"
                          >Dados do contato</a
                        >
                      </li>
                      <li>
                        <a
                          href="#aparelhos"
                          data-toggle="tab"
                          @click="carregarAbaAtiva"
                          >Aparelhos</a
                        >
                      </li>
                      <li>
                        <a
                          href="#pedido"
                          data-toggle="tab"
                          @click="carregarAbaAtiva"
                          >Pedido</a
                        >
                      </li>
                      <li>
                        <a
                          href="#observacoes"
                          data-toggle="tab"
                          @click="carregarAbaAtiva"
                          >Observações</a
                        >
                      </li>
                      <li>
                        <a
                          href="#resumo"
                          data-toggle="tab"
                          @click="carregarAbaAtiva"
                          >Resumo</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    <EmpresaComponent ref="empresa"></EmpresaComponent>
                    <ContatoComponent ref="contato"></ContatoComponent>
                    <AparelhosComponent ref="aparelhos"></AparelhosComponent>
                    <PedidoComponent ref="pedido"></PedidoComponent>
                    <ObservacoesComponent ref="observacoes"></ObservacoesComponent>
                    <ResumoComponent ref="resumo"></ResumoComponent>
                  </div>
                  <div class="wizard-footer">
                    <div class="pull-right">
                      <input
                        type="button"
                        class="btn btn-next btn-fill btn-danger btn-wd"
                        id="buttonProximo"
                        name="next"
                        value="Próximo"
                        @click="carregarAbaAtiva"
                      />
                      <input
                        type="button"
                        class="btn btn-finish btn-fill btn-danger btn-wd"
                        id="buttonFinalizar"
                        name="finish"
                        value="Finalizar"
                        @click="inserirCadastro"
                      />
                    </div>
                    <div class="pull-left">
                      <input
                        type="button"
                        class="btn btn-previous btn-fill btn-default btn-wd"
                        id="buttonAnterior"
                        name="previous"
                        value="Anterior"
                      />
                      <div class="footer-checkbox">
                        <div class="col-sm-12"></div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <!-- <div class="container text-center">
                Feito com o <i class="fa fa-heart heart"></i> por <a href="https://www.fabriziorodrigues.com">Fabrizio Rodrigues</a>.
            </div> -->
      </div>
    </div>
    <notifications group="notificacao" position="top left" />
  </div>
</template>

<script>
import EmpresaComponent from './components/EmpresaComponent.vue';
import ContatoComponent from './components/ContatoComponent.vue';
import AparelhosComponent from './components/AparelhosComponent.vue';
import PedidoComponent from './components/PedidoComponent.vue';
import ObservacoesComponent from './components/ObservacoesComponent.vue';
import ResumoComponent from './components/ResumoComponent.vue';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    EmpresaComponent,
    ContatoComponent,
    AparelhosComponent,
    PedidoComponent,
    ObservacoesComponent,
    ResumoComponent,
  },
  data: function() {
    return {
      dadosEmpresa: null,
      dadosReceitaWS: null,
      dadosContato: null,
      dadosAparelhos: null,
      dadosPedido: null,
      dadosObservacoes: null,
      abaCarregada: 1,
    };
  },
  methods: {
    carregarDadosFormulario() {
      this.dadosEmpresa = this.$refs.empresa.dadosEmpresa();
      this.dadosReceitaWS = this.$refs.empresa.dadosReceitaWS();
      this.dadosContato = this.$refs.contato.dadosContato();
      this.dadosAparelhos = this.$refs.aparelhos.dadosAparelhos();
      this.dadosPedido = this.$refs.pedido.dadosPedido();
      this.dadosObservacoes = this.$refs.observacoes.dadosObservacoes();
    },
    carregarAbaAtiva() {
      var href = '';
      window.setTimeout(() => {
        href = document
          .querySelector('#lista-abas > li.active > a')
          .getAttribute('href');

        if (href === '#observacoes' || href === '#resumo') {
          this.carregarDadosFormulario();
          this.$refs.resumo.carregadDadosApresentacaoResumo(
            this.dadosEmpresa,
            this.dadosContato,
            this.dadosPedido,
            this.dadosReceitaWS,
            this.dadosAparelhos,
            this.dadosObservacoes
          );
        }
      }, 800);
    },
    inserirCadastro() {
      var url = 'server/pedidos/cadastrar.php',
        params = new URLSearchParams(),
        cadastro = {
          empresa: this.dadosEmpresa,
          contatos: this.dadosContato,
          pedido: this.dadosPedido,
          aparelhos: this.dadosAparelhos,
          observacoes: this.dadosObservacoes
        },
        receitaWS = this.dadosReceitaWS;

      let cadastroString = JSON.stringify(cadastro),
        receitaWSString = JSON.stringify(receitaWS);

      params.append('cnpj', this.dadosEmpresa.empresa.cnpj);
      params.append('razaoSocial', this.dadosEmpresa.empresa.razao_social);
      params.append('jsonFormularioCadastro', cadastroString);
      params.append('jsonReceitaWS', receitaWSString);

      // Postando informações do cadastro no endpoint da Netcomm
      this.enviarDadosNetcommEndpoint(
        this.dadosEmpresa.empresa.cnpj,
        this.dadosEmpresa.empresa.razao_social,
        cadastroString,
        receitaWSString
      );

      axios
        .post(url, params)
        .then((resposta) => this.respostaInserirCadastro(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    respostaInserirCadastro(data) {
      window.location.href = `cadastro_detalhes.php?guid=${data}`;
    },
    enviarDadosNetcommEndpoint(
      cnpj,
      razaoSocial,
      cadastroString,
      receitaWSString
    ) {
      var url = 'server/transferir_dados_netcomm.php',
        params = new URLSearchParams();

      params.append('cnpj', cnpj);
      params.append('razaoSocial', razaoSocial);
      params.append('cadastroString', cadastroString);
      params.append('receitaWSString', receitaWSString);

      axios
        .post(url, params)
        .then((resposta) => this.respostaInserirCadastro(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
  },
};
</script>

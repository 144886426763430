<template>
  <div class="mt-3 mb-3">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Inserir novo tipo pedido</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
          <router-link
            class="btn btn-sm btn-outline-secondary"
            to="/tipo-pedido/listar"
            >VOLTAR</router-link
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 order-md-1">
        <form class="needs-validation" novalidate="">
          <div class="row">
            <div class="col-md-4 mb-6">
              <label for="textoDescricao">Ordem</label>
              <input
                type="text"
                class="form-control"
                placeholder="Ordem"
                maxlength="2"
                v-model="textoOrdem"
              />
            </div>
            <div class="col-md-8 mb-6">
              <label for="textoDescricao">Descrição</label>
              <input
                type="text"
                class="form-control"
                placeholder="Descrição"
                maxlength="100"
                v-model="textoDescricao"
              />
            </div>
          </div>
          <hr class="mb-4" />
          <button
            class="btn btn-primary btn-lg btn-block"
            type="button"
            @click="inserir"
          >
            Salvar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data: function() {
    return {
      textoOrdem: null,
      textoDescricao: null,
    };
  },
  methods: {
    inserir() {
      let formularioValido = this.validarFormulario();

      if (formularioValido !== '') {
        const options = {
          icon: 'error',
          title: 'Oops...',
          text: formularioValido,
          showConfirmButton: false,
          timer: 2500,
        };
        this.$swal(options);
        return;
      }

      var url = 'server/tipo_pedido/inserir.php',
        params = new URLSearchParams();

      params.append('textoOrdem', this.textoOrdem);
      params.append('textoDescricao', this.textoDescricao);

      axios
        .post(url, params)
        .then((resposta) => this.respostaInserir(resposta.data))
        .catch((erro) => console.error(erro));
      return;
    },
    // eslint-disable-next-line no-unused-vars
    respostaInserir(data) {
      const options = {
        icon: 'success',
        title: 'Dados salvos com sucesso',
        showConfirmButton: false,
        showCloseButton: true,
        timer: 2500,
      };
      this.$swal(options);
      window.setTimeout(() => {
        this.$router.push('/tipo-pedido/listar');
      }, 3000);
    },
    validarFormulario() {
      if (this.textoOrdem === null) {
        return 'O campo "Ordem" é obrigatório.';
      }

      if (this.textoDescricao === null) {
        return 'O campo "Descrição" é obrigatório.';
      }

      return '';
    },
  },
};
</script>

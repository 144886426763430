import Vue from 'vue';
import VueRouter from 'vue-router';
import Notifications from 'vue-notification'; // https://github.com/euvl/vue-notification/
import VueSweetalert2 from 'vue-sweetalert2'; // https://www.npmjs.com/package/vue-sweetalert2
//import routes from './routes';

Vue.config.productionTip = false;
Vue.config.devtools = true; // Colocar no FALSE quando for para PRODUÇÃO

// Registro global de componentes
Vue.component('main-app', require('./App.vue').default);
Vue.component('main-app-detalhes', require('./AppDetalhes.vue').default);
Vue.component('main-app-listagem', require('./AppListagem.vue').default);
Vue.component('main-app-forms', require('./AppForms.vue').default);
Vue.component('users-grid', require('./components/UsersGrid.vue').default);

Vue.use(Notifications);
Vue.use(VueSweetalert2);
Vue.use(VueRouter);

window.Vue = Vue;

import tipoPedidoListar from './views/tipo_pedidos/listar';
import tipoPedidoInserir from './views/tipo_pedidos/inserir';
import tipoPedidoEditar from './views/tipo_pedidos/editar';
import tipoPedidoRemover from './views/tipo_pedidos/remover';
import tipoAparelhoListar from './views/tipo_aparelho/listar';
import tipoAparelhoInserir from './views/tipo_aparelho/inserir';
import tipoAparelhoEditar from './views/tipo_aparelho/editar';
import tipoAparelhoRemover from './views/tipo_aparelho/remover';
import pacotesListar from './views/pacotes/listar';
import pacotesInserir from './views/pacotes/inserir';
import pacotesEditar from './views/pacotes/editar';
import pacotesRemover from './views/pacotes/remover';

const routes = [
  // Aba PEDIDO
  {
    path: '/tipo-pedido/listar',
    name: 'tipo-pedido-listar',
    component: tipoPedidoListar,
  },
  {
    path: '/tipo-pedido/inserir',
    name: 'tipo-pedido-inserir',
    component: tipoPedidoInserir,
  },
  {
    path: '/tipo-pedido/editar/:id',
    name: 'tipo-pedido-editar',
    component: tipoPedidoEditar,
  },
  {
    path: '/tipo-pedido/remover/:id',
    name: 'tipo-pedido-remover',
    component: tipoPedidoRemover,
  },  
  {
    path: '/pacotes/listar',
    name: 'pacotes-listar',
    component: pacotesListar,
  },
  {
    path: '/pacotes/inserir',
    name: 'pacotes-inserir',
    component: pacotesInserir,
  },
  {
    path: '/pacotes/editar/:id',
    name: 'pacotes-editar',
    component: pacotesEditar,
  },
  {
    path: '/pacotes/remover/:id',
    name: 'pacotes-remover',
    component: pacotesRemover,
  },
  // Aba APARELHO
  {
    path: '/tipo-aparelho/listar',
    name: 'tipo-aparelho-listar',
    component: tipoAparelhoListar,
  },
  {
    path: '/tipo-aparelho/inserir',
    name: 'tipo-aparelho-inserir',
    component: tipoAparelhoInserir,
  },
  {
    path: '/tipo-aparelho/editar/:id',
    name: 'tipo-aparelho-editar',
    component: tipoAparelhoEditar,
  },
  {
    path: '/tipo-aparelho/remover/:id',
    name: 'tipo-aparelho-remover',
    component: tipoAparelhoRemover,
  },
];
const router = new VueRouter({
  routes,
});
new Vue({
  router,
}).$mount('#mainAppForms');
